import { create, all, isUnit } from "mathjs";

import { RESULT_TYPE_MATH } from "../constants.js";

const math = create(all);
const limitedEvaluate = math.evaluate;

math.import(
  {
    import: function importImpl() {
      throw new Error("Function import is disabled");
    },
    createUnit: function createFunction() {
      throw new Error("Function createUnit is disabled");
    }
  },
  { override: true }
);

export default function calculate(normalizedQuery, originalQuery) {
  const nullResult = {
    relevance: 0,
    type: RESULT_TYPE_MATH
  };
  let evaluatedResult = "";
  let relatedQueries = [
    "3 + 4",
    "4^5 - 1",
    "1 + 5 / (4 * 2)",
    "7 / 3 * (3 / 2)",
    "7 - 9 / (4 - 2)",
    "4 * 1 / (4 + 2)",
    "sqrt(64)",
    "2^4",
    "15% * 89.95",
    "cos(45 deg)",
    "abs(-0.05)",
    "round(43.623532)"
  ]
    .sort(() => (Math.random() > 0.5 ? -1 : 1))
    .slice(0, 4);

  try {
    evaluatedResult = limitedEvaluate(normalizedQuery);
  } catch {
    // Error potentially expected
  }

  try {
    evaluatedResult = limitedEvaluate(originalQuery);
  } catch {
    return nullResult;
  }

  if (typeof evaluatedResult === "number") {
    return {
      relevance: 1,
      result: evaluatedResult,
      data: {
        value: evaluatedResult
      },
      relatedQueries,
      type: RESULT_TYPE_MATH
    };
  }
  if (
    typeof evaluatedResult !== "object" ||
    evaluatedResult.toNumeric() === null
  ) {
    return nullResult;
  }

  if (isUnit(evaluatedResult)) {
    relatedQueries = ["4 feet to meters", " 1lb to ounces", "13 cm to in"];
    return {
      relevance: 1,
      result: evaluatedResult.format({ precision: 5 }),
      data: {
        unit: evaluatedResult.units[0].unit.name,
        value: evaluatedResult.toNumeric()
      },
      relatedQueries,
      type: RESULT_TYPE_MATH
    };
  }

  return nullResult;
}
