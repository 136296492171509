import React, { PureComponent } from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import { wrapTheme } from "./Theme";

class RelatedQueries extends PureComponent {
  onQuery = event => {
    this.props.updateQuery(event.target.value);
  };

  render() {
    const { relatedQueries, theme } = this.props;

    if (relatedQueries.length === 0) {
      return null;
    }

    const styles = StyleSheet.create({
      relatedQueries: {
        marginTop: theme.baseUnit,
        opacity: 0.7
      },
      title: {
        fontFamily: theme.typography.heading,
        fontWeight: "normal",
        fontSize: ".9rem",
        fontStyle: "italic",
        margin: `${theme.baseUnit * 4}px 0 ${theme.baseUnit}px`
      },
      query: {
        fontSize: ".8rem",
        color: theme.colors.secondaryFont,
        border: 0,
        background: "none",
        display: "flex",
        padding: `${theme.baseUnit / 2}px 0`,
        textDecoration: "underline",
        cursor: "pointer"
      }
    });

    return (
      <div className={css(styles.relatedQueries)}>
        <h2 className={css(styles.title)}>Related queries</h2>
        {relatedQueries.map(query => {
          return (
            <button
              className={css(styles.query)}
              onClick={this.onQuery}
              value={query}
              key={query}
            >
              {query}
            </button>
          );
        })}
      </div>
    );
  }
}

RelatedQueries.defaultProps = {
  relatedQueries: []
};

export default wrapTheme(RelatedQueries);
