import React from "react";
import { StyleSheet, css } from "aphrodite/no-important";

export default function RandomNumberResult({
  data,
  relatedQueries,
  explanations,
  renderRelatedQueries,
  renderExplainers
}) {
  const styles = StyleSheet.create({
    mainNumber: {
      fontSize: "3rem",
      fontWeight: "bold",
      marginBottom: "2rem"
    }
  });

  return (
    <div>
      <div className={css(styles.mainNumber)}>
        {data.number.toLocaleString()}
      </div>
      {renderRelatedQueries(relatedQueries)}
      {renderExplainers(explanations)}
    </div>
  );
}
