import React from "react";

export default function DefaultResult({
  result,
  explanations,
  relatedQueries,
  renderRelatedQueries,
  renderExplainers
}) {
  return (
    <div>
      {result} {renderRelatedQueries(relatedQueries)}
      {renderExplainers(explanations)}
    </div>
  );
}
