import math from "./math";
import query from "./query";
import randomNumber from "./randomNumber";

export default function search(originalQuery) {
  const { normalizedQuery, parsedQuery } = query(originalQuery);
  return [
    math(normalizedQuery, originalQuery),
    randomNumber(parsedQuery, originalQuery)
  ]
    .filter(({ relevance }) => relevance > 0)
    .sort((a, b) => {
      return a.relevance > b.relevance ? -1 : 1;
    });
}
