import React, { Component } from "react";

export const themes = new Map([
  [
    "light",
    {
      id: "light",
      name: "Light",
      colors: {
        primaryFont: "#000000",
        primaryBackground: "#FFFFFF",
        primaryBorder: "#A7A7A7",
        secondaryFont: "#060606",
        secondaryBackground: "#E8E8E8",
        secondaryBorder: "#FFFFFF",
        accentBackground: "#0b3889",
        accentFont: "#f7f7f7",
        accentBorder: "#fcde00"
      },
      typography: {
        heading: '"Raleway", sans-serif',
        body: '"Lato", sans-serif'
      },
      baseUnit: 8
    }
  ],
  [
    "dark",
    {
      id: "dark",
      name: "Dark",
      colors: {
        primaryFont: "#FFFFFF",
        primaryBackground: "#262626",
        primaryBorder: "#5B5B5B",
        secondaryFont: "#FFFFFF",
        secondaryBackground: "#383838",
        secondaryBorder: "#000000",
        accentBackground: "#0b3889",
        accentFont: "#f7f7f7",
        accentBorder: "#fcde00"
      },
      typography: {
        heading: '"Raleway", sans-serif',
        body: '"Lato", sans-serif'
      },
      baseUnit: 8
    }
  ],
  [
    "mint",
    {
      id: "mint",
      name: "Mint",
      colors: {
        primaryFont: "#000000",
        primaryBackground: "#81FFD1",
        primaryBorder: "#A7A7A7",
        secondaryFont: "#060606",
        secondaryBackground: "#A5F4E3",
        secondaryBorder: "#FFFFFF",
        accentBackground: "#0b3889",
        accentFont: "#f7f7f7",
        accentBorder: "#fcde00"
      },
      typography: {
        heading: '"Raleway", sans-serif',
        body: '"Lato", sans- serif'
      },
      baseUnit: 8
    }
  ]
]);

export const ThemeContext = React.createContext({
  theme: themes.get("light"),
  onChangeTheme: () => {}
});

export const ThemeProvider = ThemeContext.Provider;

export function wrapTheme(WrappedComponent) {
  class WrappedTheme extends Component {
    render() {
      const theme = this.context;
      return <WrappedComponent {...this.props} theme={theme} />;
    }
  }
  WrappedTheme.displayName = `wrapTheme(${getDisplayName(WrappedComponent)})`;
  WrappedTheme.contextType = ThemeContext;

  return WrappedTheme;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}
