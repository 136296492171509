import React, { PureComponent } from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import { wrapTheme } from "./Theme";

class Explainers extends PureComponent {
  render() {
    const { explanations, theme } = this.props;

    if (explanations.length === 0) {
      return null;
    }

    const styles = StyleSheet.create({
      container: {
        opacity: 0.7
      },
      explanations: {
        padding: `0 ${theme.baseUnit * 2}px`,
        margin: `0 ${theme.baseUnit}px`
      },
      title: {
        fontFamily: theme.typography.heading,
        fontWeight: "normal",
        fontSize: ".9rem",
        fontStyle: "italic",
        margin: `${theme.baseUnit * 4}px 0 ${theme.baseUnit}px`
      },
      explanation: {
        fontSize: ".8rem",
        color: theme.colors.secondaryFont,
        padding: `${theme.baseUnit / 2}px 0`
      }
    });

    return (
      <div className={css(styles.container)}>
        <h2 className={css(styles.title)}>About this result</h2>
        <ul className={css(styles.explanations)}>
          {explanations.map(explanation => {
            return (
              <li className={css(styles.explanation)} key={explanation}>
                {explanation}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

Explainers.defaultProps = {
  explanations: []
};

export default wrapTheme(Explainers);
