import React from "react";
import { StyleSheet, css } from "aphrodite/no-important";

export default function MathResult({
  data,
  relatedQueries,
  explanations,
  renderRelatedQueries,
  renderExplainers
}) {
  const styles = StyleSheet.create({
    answer: {
      display: "flex",
      marginBottom: "2rem",
      alignItems: "flex-end",
      lineHeight: "2rem"
    },
    mainNumber: {
      fontSize: "2rem",
      fontWeight: "bold"
    },
    unit: {
      textIndent: ".5rem"
    }
  });
  console.log(data);
  return (
    <div>
      <div className={css(styles.answer)}>
        <div className={css(styles.mainNumber)}>
          {data.value.toFixed(4).toLocaleString()}
        </div>
        <div className={css(styles.unit)}>{data.unit}</div>
      </div>
      {renderRelatedQueries(relatedQueries)}
      {renderExplainers(explanations)}
    </div>
  );
}

MathResult.defaultProps = {
  data: {
    value: 0
  }
};
