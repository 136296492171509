import React, { PureComponent } from "react";
import { wrapTheme, themes } from "./Theme";
import { StyleSheet, css } from "aphrodite/no-important";

class Settings extends PureComponent {
  onChangeTheme = event => {
    const themeId = event.target.value;
    const theme = themes.get(themeId);
    console.log(themeId, theme);
    this.props.theme.onChangeTheme(theme);
  };

  render() {
    const { theme } = this.props;
    const styles = StyleSheet.create({
      themeSection: {},
      themeRadio: {
        visibility: "hidden",
        display: "none"
      },
      themeBlock: {
        height: theme.baseUnit * 12,
        width: theme.baseUnit * 12,
        display: "flex",
        border: `1px solid ${theme.colors.primaryBorder}`,
        margin: theme.baseUnit,
        flexDirection: "column",
        justifyItems: "center",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        textAlign: "center",
        cursor: "pointer"
      },
      themeBlocks: {
        display: "flex"
      }
    });
    return (
      <div>
        <h1>Settings</h1>
        <section className={css(styles.themeSection)}>
          <h2>Theme</h2>
          <div className={css(styles.themeBlocks)}>
            {Array.from(themes.values()).map(({ id, name, colors }) => {
              return (
                <label
                  className={css(styles.themeBlock)}
                  key={id}
                  style={{
                    color: colors.primaryFont,
                    backgroundColor: colors.primaryBackground
                  }}
                >
                  {name}
                  <input
                    onChange={this.onChangeTheme}
                    className={css(styles.themeRadio)}
                    type="radio"
                    value={id}
                    name="theme"
                  />
                </label>
              );
            })}
          </div>
        </section>
      </div>
    );
  }
}

export default wrapTheme(Settings);
