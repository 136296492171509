import { RESULT_TYPE_RANDOM_NUMBER } from "../constants.js";

const MAX_32_BIT_NUMBER = Math.pow(2, 32) - 1;

export default function randomNumber(parsedQuery, originalQuery) {
  const nullResult = {
    relevance: 0,
    type: RESULT_TYPE_RANDOM_NUMBER
  };

  const isRandomNumberSearch =
    parsedQuery
      .if("random")
      .if("number")
      .out("text").length > 0;

  if (!isRandomNumberSearch) {
    return nullResult;
  }

  let digits = 1;

  const digitModification = parsedQuery
    .match(". digit?")
    .values()
    .numbers()[0];

  if (digitModification) {
    digits = digitModification;
  }

  const isSecure = !!window.crypto;
  const randomNumber = isSecure
    ? window.crypto.getRandomValues(new Uint32Array(1))[0] / MAX_32_BIT_NUMBER
    : Math.random();
  const explanation = `Displaying a random number between 1 and ${Math.pow(
    10,
    digits
  ).toLocaleString()}`;
  const security = isSecure
    ? "This number was generated from a secure function. In technical speak this means the number was generated using a crypto-grade psuedo-random number generator with a high-level of entropy."
    : "This number is psuedo-random, if secure randomness is required upgrade the browser.";
  const stopNumber = Math.pow(10, digits) - 1;
  const number = Math.round(randomNumber * stopNumber) + 1;

  const result = {
    relevance: 1,
    result: number.toLocaleString(),
    data: {
      number,
      isSecure
    },
    explanations: [explanation, security],
    relatedQueries: [
      "Random number",
      "4 digit random number",
      "2 digit random number"
    ].filter(query => query.toLowerCase() !== originalQuery.toLowerCase()),
    type: RESULT_TYPE_RANDOM_NUMBER
  };

  return result;
}
