import React, { PureComponent } from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import { wrapTheme } from "./Theme";
import Search from "./Search";
import IconSearch from "@material-ui/icons/Search";
import IconSettingsApplications from "@material-ui/icons/SettingsApplicationsOutlined";
import IconAbout from "@material-ui/icons/BookOutlined";
import { Route, Link } from "react-router-dom";
import Settings from "./Settings";
import About from "./About";

class Main extends PureComponent {
  render() {
    const { theme } = this.props;
    const styles = StyleSheet.create({
      main: {
        padding: theme.baseUnit * 2,
        flexGrow: 1
      },
      container: {
        backgroundColor: theme.colors.primaryBackground,
        color: theme.colors.primaryFont,
        minHeight: "100vh",
        display: "flex"
      },
      menu: {
        background: theme.colors.accentBackground,
        borderRight: `${theme.colors.primaryBorder} 1px solid`,
        width: theme.baseUnit * 25,
        display: "flex",
        flexDirection: "column",
        flexShrink: 0
      },
      menuIcon: {
        marginRight: theme.baseUnit * 2
      },
      menuItem: {
        background: theme.colors.accentBackground,
        display: "flex",
        height: theme.baseUnit * 4,
        alignContent: "center",
        alignItems: "center",
        color: theme.colors.accentFont,
        textDecoration: "none",
        padding: theme.baseUnit * 3,
        ":hover": {
          color: theme.colors.accentBorder
        },
        ":focus": {
          color: theme.colors.accentBorder
        }
      }
    });

    return (
      <div className={css(styles.container)}>
        <div className={css(styles.menu)}>
          <Link className={css(styles.menuItem)} to="/">
            <IconSearch className={css(styles.menuIcon)} /> Search
          </Link>
          <Link className={css(styles.menuItem)} to="/settings/">
            <IconSettingsApplications className={css(styles.menuIcon)} />
            Settings
          </Link>
          <Link className={css(styles.menuItem)} to="/about/">
            <IconAbout className={css(styles.menuIcon)} />
            About
          </Link>
        </div>
        <div className={css(styles.main)}>
          <Route path="/" exact component={Search} />
          <Route path="/settings/" component={Settings} />
          <Route path="/about/" component={About} />
        </div>
      </div>
    );
  }
}

export default wrapTheme(Main);
