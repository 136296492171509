import nlp from "compromise";

export default function query(originalQuery) {
  const parsedQuery = nlp(originalQuery);
  const numericQuery = parsedQuery
    .values()
    .toNumber()
    .out();
  const normalizedQuery = parsedQuery.normalize().out("text");

  return { originalQuery, numericQuery, normalizedQuery, parsedQuery };
}
