import React, { PureComponent } from "react";
import { flushToStyleTag } from "aphrodite/no-important";
import { ThemeProvider, themes } from "./Theme";
import Main from "./Main";
import { BrowserRouter as Router } from "react-router-dom";

const isDarkMode =
  typeof window.matchMedia === "function"
    ? window.matchMedia("(prefers-color-scheme: dark)").matches
    : false;

class App extends PureComponent {
  state = {
    theme: isDarkMode ? themes.get("dark") : themes.get("light")
  };

  componentDidMount() {
    if (typeof window.matchMedia === "function") {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addListener(this.setDarkTheme);
      window
        .matchMedia("(prefers-color-scheme: light)")
        .addListener(this.setLightTheme);
    }
  }

  componentWillUnmount() {
    if (typeof window.matchMedia === "function") {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", this.setDarkTheme);
      window
        .matchMedia("(prefers-color-scheme: light)")
        .removeEventListener("change", this.setLightTheme);
    }
  }

  setLightTheme = e => {
    if (e.matches) {
      this.changeTheme(themes.get("light"));
    }
  };

  setDarkTheme = e => {
    if (e.matches) {
      this.changeTheme(themes.get("dark"));
    }
  };

  changeTheme = theme => {
    this.setState({ theme }, () => {
      flushToStyleTag();
    });
  };

  render() {
    const { theme } = this.state;
    return (
      <Router>
        <ThemeProvider
          value={{
            ...theme,
            onChangeTheme: this.changeTheme
          }}
        >
          <Main />
        </ThemeProvider>
      </Router>
    );
  }
}

export default App;
