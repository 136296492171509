import React, { PureComponent } from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import engine from "./engine";
import { wrapTheme } from "./Theme";
import { RESULT_TYPE_RANDOM_NUMBER, RESULT_TYPE_MATH } from "./constants";
import Default from "./views/Default";
import RandomNumber from "./views/RandomNumber";
import MathResult from "./views/Math";
import RelatedQueries from "./RelatedQueries";
import Explainers from "./Explainers";

class Search extends PureComponent {
  state = {
    query: "",
    debouncedQuery: "",
    results: [],
    isFetchingResults: false
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.debouncedQuery !== this.state.debouncedQuery) {
      this.fetchResults();
    }
  }

  onQuery = event => {
    const value = event.target.value;
    this.setState({
      query: value
    });

    clearTimeout(this.timoutId);

    this.timoutId = setTimeout(() => {
      this.setState({
        debouncedQuery: value
      });
    }, 250);
  };

  onQueryUndebounced = value => {
    this.setState({
      debouncedQuery: value,
      query: value
    });
  };

  fetchResults() {
    this.setState({
      isFetchingResults: true,
      results: []
    });

    const resultPromise = new Promise(resolve => {
      const { debouncedQuery } = this.state;

      // Simulate async pause for now, once code is using WebWorkers, remove this
      window.setTimeout(() => {
        resolve(engine(debouncedQuery));
      }, 100);
    });

    resultPromise.then(results => {
      this.setState({ results, isFetchingResults: false });
    });
  }

  renderRelatedQueries = relatedQueries => {
    return (
      <RelatedQueries
        updateQuery={this.onQueryUndebounced}
        relatedQueries={relatedQueries}
      />
    );
  };

  renderExplainers = explanations => {
    return <Explainers explanations={explanations} />;
  };

  render() {
    const { query, results, isFetchingResults } = this.state;
    const { theme } = this.props;

    const styles = StyleSheet.create({
      search: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: `calc(95vh - ${theme.baseUnit * 6})`,
        paddingBottom: theme.baseUnit * 2
      },
      genericResult: {
        fontSize: "36px",
        fontWeight: "bold"
      },
      input: {
        "-webkit-appearance": "none",
        background: theme.colors.primaryBackground,
        border: 0,
        borderBottom: `1px solid ${theme.colors.primaryBorder}`,
        color: theme.colors.primaryFont,
        width: "100%",
        padding: `${theme.baseUnit * 2} 0`,
        marginBottom: theme.baseUnit * 2,
        height: theme.baseUnit * 7,
        margin: `${theme.baseUnit * 3} 0`,
        borderRadius: 0,
        fontFamily: theme.typography.heading,
        fontSize: "18px",
        "::placeholder": {
          fontFamily: theme.typography.body,
          fontSize: "18px"
        },
        ":focus": {
          outline: 0
        }
      }
    });

    return (
      <div className={css(styles.search)}>
        <div className={css(styles.headsupDisplay)}>
          <label>
            <input
              className={css(styles.input)}
              label=""
              placeholder="3 feet to yards"
              onFocus={this.onFocus}
              onChange={this.onQuery}
              onBlur={this.onBlur}
              value={query}
            />
            {results.length === 0 && query.length === 0 && (
              <div>
                <p>
                  Enter a question to find answer. Need inspiration? Here are a
                  few questions we've been asked:
                </p>
                <ul>
                  <li>What is 4 feet in meters?</li>
                  <li>When is sunset today?</li>
                  <li>
                    How much is a $10,000 mortage at 4.25% interest for 5 years?
                  </li>
                  <li>What is the population of New York City?</li>
                </ul>
              </div>
            )}
          </label>

          {isFetchingResults && "Loading ..."}
          {results.length > 0 &&
            results.map(result => {
              switch (result.type) {
                case RESULT_TYPE_RANDOM_NUMBER: {
                  return (
                    <RandomNumber
                      {...result}
                      renderExplainers={this.renderExplainers}
                      renderRelatedQueries={this.renderRelatedQueries}
                      key={result.type}
                    />
                  );
                }
                case RESULT_TYPE_MATH: {
                  return (
                    <MathResult
                      {...result}
                      renderExplainers={this.renderExplainers}
                      renderRelatedQueries={this.renderRelatedQueries}
                      key={result.type}
                    />
                  );
                }

                default:
                  return (
                    <Default
                      renderExplainers={this.renderExplainers}
                      renderRelatedQueries={this.renderRelatedQueries}
                      key={result.type}
                      {...result}
                    />
                  );
              }
            })}
        </div>
      </div>
    );
  }
}

export default wrapTheme(Search);
